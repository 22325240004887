import { PaperProps, Stack, styled, Typography } from "@mui/material";
import { BORDER_RADIUS } from "../theme";
import { Link } from "react-router-dom";
import { ReactNode } from "react";
const CARD_PADDING = 3;

export function Card({ children, sx, ...props }: PaperProps) {
  return (
    <Stack
      sx={{
        padding: 3,
        gap: 2,
        backgroundColor: ({ palette }) => palette.background.paper,
        border: ({ palette }) => `1px solid ${palette.divider}`,
        borderRadius: BORDER_RADIUS,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
}

export function SecondaryCard({ children, sx, ...props }: PaperProps) {
  return (
    <Stack
      sx={{
        padding: 3,
        gap: 2,
        backgroundColor: ({ palette }) => palette.background.default,
        border: ({ palette }) => `1px solid ${palette.divider}`,
        // borderTop: ({ palette }) => `4px solid ${palette.secondary.main}`,
        boxShadow: ({ palette }) => `0px 0px 8px -4px ${palette.secondary.main} `,
        borderRadius: BORDER_RADIUS,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
}

export const Span = styled("span")({});

export const AppLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
});

type HeaderProps = {
  header: string;
  body: string | string[];
  secondary?: boolean 
};

export function HeaderCard({
  children,
  ...headerProps
}: {
  children: ReactNode;
} & HeaderProps) {
  return headerProps.secondary ? (
      <SecondaryCard
      sx={{
        width: "100%",
        p: 0,
        justifyContent: "space-between",
        overflow: "hidden",
        flexGrow: 1,
      }}
    >
      <Header {...headerProps} />

      {children}
    </SecondaryCard>
    ) : (<Card
    sx={{
      width: "100%",
      p: 0,
      justifyContent: "space-between",
      overflow: "hidden",
      flexGrow: 1,
    }}
  >
    <Header {...headerProps} />

    {children}
  </Card>);
}

export function Header({ header, body }: HeaderProps) {
  const lines = typeof body === "string" ? [body] : body;
  return (
    <Stack sx={{ p: CARD_PADDING }} spacing={1}>
      <Typography variant="h3">{header} </Typography>
      {lines.map((line, i) => (
        <Typography key={i} variant="body2" color="text.secondary">
          {line}
        </Typography>
      ))}
    </Stack>
  );
}
