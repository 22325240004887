import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Card, Column, Image, Row, HeaderCard, MediaStack } from "../common";
import { MEDIA, SCREENSHOTS,COPY, NAV_BREAKPOINT } from "../../constants";

const CARD_PADDING = 3;
const SPACING = 3;
const BREAKPOINT = MEDIA.LG;

export default function Protocol() {
    const isMobile = useMediaQuery(BREAKPOINT.below);
    return (
      <Column spacing={6} py={6}>
        <MediaStack
                query={BREAKPOINT}
                sx={{ position: "relative" }}
                sxBelow={{ alignItems: "flex-start" }}
                sxAbove={{ alignItems: "stretch" }}
                spacing={6}
              >
          <Column spacing={2}>
            <Typography variant="h1">{COPY.PROTOCOL.title} </Typography>
            <Typography variant="h2" sx={{fontWeight: 400}} color="text.secondary">
              {COPY.PROTOCOL.subtitle}
            </Typography>
          </Column>
          <Image
                    alt="order book and loan diagram"
                    variant="fixed-height"
                    width="auto"
                    height="100%"
                    query={BREAKPOINT}
                    mobileHeight="180px"
                    src={SCREENSHOTS.protocol}
                  />

              </MediaStack>
        
        <Benefits/>
      </Column>
    );
  }

  const BENEFITS = COPY.PROTOCOL.benefits;



  function Benefits() {
  return (

    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap:2,
        [NAV_BREAKPOINT.below]: {
          gridTemplateColumns: "1fr 1fr",
        },
        [MEDIA.SM.below]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      {BENEFITS.map(({ header, body }, i) => (
              <Card key={i} sx={{ p: 2, gap: 1, flexGrow: 1 }}>

          <Typography> {header} </Typography>
          <Typography color="text.secondary" variant="body2">
            {body}
          </Typography>
        </Card>
      ))}
    </Stack>

  );
}