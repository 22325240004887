const GLOBAL = {
    defaultCta: 'Join the Beta'
}
const HERO = {
    title: 'Atomic Lending Markets',
    subtitle: 'Loopscale is a new way to lend and borrow onchain. Liquidity for any asset, at the best rate, with less risk.',
    cta: GLOBAL.defaultCta
}
const BENEFITS = {
    title: 'Get the flexibility of order books...',
    riskHeader: 'Custom risk profiles',
    riskBody: 'Decide terms, rates, and exposure. No more one-size-fits-all constraints. ',
    collateralHeader: 'Liquidity for any asset',
    collateralBody: 'Access $100B+ in untapped value across liquidity positions, staked assets and emerging markets.',
    ratesHeader:'Lower borrow costs and higher lend yields',
    ratesBody: 'Fixed, market-driven rates eliminate the spread between borrowing and lending, maximizing returns for everyone.',
    structureHeader: 'Structuring and fixed-rate capabilities',
    structureBody: 'Atomic Markets match lenders and borrowers directly, enabling flexible and predictable terms.',
}

const FEATURES = {
    title: '...with the simplicity of pools',
    vaultsHeader: 'Loopscale Vaults',
    vaultsBody: 'Access passive lending exposure by depositing to Loopscale Vaults, each with a unique risk profile and strategy curated by a third-party risk expert.',
    vaultsCta: 'Deposit',
    loopsHeader:'Yield Loops',
    loopsBody:'Multiply rewards on LSTs, LPs, and more. Yield Loops are one-click leverage products with fixed rates and predictable returns.',
    loopsCta:'Loop'
}
const PROTOCOL = {
    title: 'The foundation for the next era of onchain finance',
    subtitle: 'Loopscale’s core protocol matches borrowers and lenders on Atomic Markets, each defined by terms, collateral, and rates.',
    // subtitle: 'A set of primitives that enable advanced financial products, from structured credit to embedded finance.',
    // orderbookHeader: 'Credit Order Book',
    // orderbookBody: 'Loopscale’s core protocol matches borrowers and lenders on Atomic Markets, each defined by terms, collateral, and rates.',
    benefits: [
        {
            header: 'Multi-parameter controls',
            body: 'Granular risk controls for diverse and custom investment profiles ',
        },
        {
            header: 'Isolated collateral',
            body: ' Support for complex assets and cross-protocol asset management.',
        },
        {
            header: 'Direct matching',
            body: 'Maximal capital efficiency with better terms for everyone',
        },
        {
            header: 'Modular architecture',
            body: 'External risk and liquidity primitives offer market depth without sacrificing scalability',
        }
    ]
}

export const COPY = {GLOBAL,HERO, BENEFITS, FEATURES, PROTOCOL}