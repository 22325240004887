import { AppBar, Stack } from "@mui/material";
import { Navbar } from "../containers";
import Hero from "./Hero";
import { BORDER_RADIUS, OUTER_MARGIN, SPACING_PX_VAL } from "../theme";
import FeaturedIssuers from "./FeaturedIssuers";
import { useScrollPast } from "../../util";
import { APP_PADDING, NAV_BREAKPOINT } from "../../constants";

const BREAKPOINT = NAV_BREAKPOINT;
export default function HeroWithNav() {
  const { ref, hasScrolledPast } = useScrollPast();

  return (
    <Stack sx={{ [BREAKPOINT.above]: { minHeight: "100vh" } }}>
      <Stack
        id="hero-with-nav"
        ref={ref}
        sx={{
          width: `calc(100% - ${SPACING_PX_VAL * OUTER_MARGIN * 2}px)`,
          m: OUTER_MARGIN,
          position: "relative",
          zIndex: ({ zIndex }) => zIndex.appBar + 1,
          background: ({ palette }) => palette.background.paper,
          border: ({ palette }) => `1px solid ${palette.divider}`,
          borderRadius: BORDER_RADIUS,
          flexGrow: 1,
        }}
      >
        <Stack
          sx={{
            py: 2,
            flexGrow: 1,
            px: APP_PADDING.desktop,
            position: 'relative',
            [BREAKPOINT.below]: { px: APP_PADDING.mobile },
            overflow: "hidden !important",
          }}
        >
          <Navbar />
          <Hero />
        </Stack>
      </Stack>
      <AppBar
        elevation={0}
        sx={{
          p: OUTER_MARGIN,
          px: APP_PADDING.desktop,
          [BREAKPOINT.below]: { px: APP_PADDING.mobile },
          opacity: hasScrolledPast ? 1 : 0,
          pointerEvents: hasScrolledPast ? "inherit" : "none",
          background: ({ palette }) => palette.background.default,
        }}
        position="fixed"
      >
        <Navbar />
      </AppBar>
      <Stack mx={OUTER_MARGIN}>
        <FeaturedIssuers />
      </Stack>
    </Stack>
  );
}
