import { Button, Typography } from "@mui/material";
import {
  Column,
  DiscordSvg,
  ExternalLink,
  LogoSvg,
  MediaStack,
  TwitterSvg,
} from "../common";
import {
  APP_SIGNUP,
  DISCORD_URL,
  GRAPHICS,
  MEDIA,
  TWITTER_URL,
} from "../../constants";
import { BORDER_RADIUS, OUTER_MARGIN, SPACING_PX_VAL } from "../theme";
import { MaxWidthWrapper } from "../Page";

export default function Community() {
  return (
    <Column
      sx={{
        width: `calc(100% - ${SPACING_PX_VAL * OUTER_MARGIN * 2}px)`,
        m: OUTER_MARGIN,
        backgroundColor: ({ palette }) => palette.background.paper,
        border: ({ palette }) => `1px solid ${palette.divider}`,
        boxShadow: ({ palette }) => `0px 0px 16px -4px ${palette.secondary.main} `,
        borderRadius: BORDER_RADIUS,
      }}
      alignItems="center"
      textAlign="center"
      spacing={4}
    >
      <MaxWidthWrapper maxWidth="730px" spacing={6} sx={{ py: 18 }}>
        <Typography variant="subtitle1">
          Borrow and lend on Loopscale today
        </Typography>
        <MediaStack query={MEDIA.MD} mobileSpacing={2} spacing={3}>
          <ExternalLink sx={{ width: "100%" }} href={APP_SIGNUP}>
            <Button fullWidth>
              <LogoSvg /> Join the Beta
            </Button>
          </ExternalLink>
          <ExternalLink sx={{ width: "100%" }} href={DISCORD_URL}>
            <Button fullWidth>
              <DiscordSvg /> Join Discord
            </Button>
          </ExternalLink>
          <ExternalLink sx={{ width: "100%" }} href={TWITTER_URL}>
            <Button fullWidth>
              <TwitterSvg /> Follow on X
            </Button>
          </ExternalLink>
        </MediaStack>
      </MaxWidthWrapper>
    </Column>
  );
}
