import { Stack } from "@mui/material";
import {
  HeroWithNav,
  Benefits,
  Features,
  Orderbook,
  Partners,
  Community,
  Footer,
  Protocol,
} from "../home";
import { MaxWidthWrapper } from "../Page";

export default function Home() {
  return (
    <>
      <HeroWithNav />

      <Stack my={16} alignItems="center" spacing={16}>
        <MaxWidthWrapper spacing={16}>
          <Benefits />
          <Features/>
          <Protocol />
        </MaxWidthWrapper>

        <MaxWidthWrapper spacing={16}>
          <Partners />
        </MaxWidthWrapper>
      </Stack>
      <Community />
      <Footer />
    </>
  );
}
