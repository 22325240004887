const S3_URL = "https://loopscale-site.s3.amazonaws.com";

const SCREENSHOT_BASE = S3_URL + "/screenshots";
export const SCREENSHOTS = {
  collateral: `${SCREENSHOT_BASE}/collateral.png`,
  structure: `${SCREENSHOT_BASE}/structure.png`,
  rates: `${SCREENSHOT_BASE}/rates.png`,
  risk: `${SCREENSHOT_BASE}/risk.png`,
  primeLoan: `${SCREENSHOT_BASE}/prime-loan.png`,
  protocol: `${SCREENSHOT_BASE}/protocol.png`,
  vaults: `${SCREENSHOT_BASE}/vaults.png`,
  loops: `${SCREENSHOT_BASE}/loops.png`,
};

const GRAPHICS_BASE = S3_URL + "/graphics";
export const GRAPHICS = {
  arcs: `${GRAPHICS_BASE}/arcs.svg`,
  loops: `${GRAPHICS_BASE}/loops.svg`,
  orderbook: `${GRAPHICS_BASE}/book.png`,
  marbleVideo: `${GRAPHICS_BASE}/marble.mp4`,
  hero: `${GRAPHICS_BASE}/hero.png`
};

const COMPANIES_BASE = S3_URL + "/orgs";
export const COMPANIES = {
  r40: `${COMPANIES_BASE}/r40.png`,
  jump: `${COMPANIES_BASE}/jump.png`,
  coinbase: `${COMPANIES_BASE}/coinbase.png`,
  solanaVentures: `${COMPANIES_BASE}/solana-ventures.png`,
  coinFund: `${COMPANIES_BASE}/coinfund.png`,
};
