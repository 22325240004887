import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Card, Column, Image, Row, HeaderCard } from "../common";
import { MEDIA, SCREENSHOTS,COPY } from "../../constants";

const CARD_PADDING = 3;
const SPACING = 3;
const BREAKPOINT = MEDIA.LG;
export default function Features() {
  const isMobile = useMediaQuery(BREAKPOINT.below);
  return (
    <Column spacing={6}>
      <Typography variant="h1">
        {COPY.FEATURES.title}
      </Typography>
      {isMobile ? <Mobile /> : <Desktop />}
    </Column>
  );
}

function Desktop() {
  return (
    <Row alignItems="stretch" spacing={SPACING}>
        <Row alignItems="stretch" spacing={SPACING}>
          <Vaults />
          <Loops />
        </Row>
    </Row>
  );
}

function Mobile() {
  return (
    <Stack spacing={SPACING}>
      <Vaults/>
      <Loops/>
    </Stack>
  );
}

function Vaults() {
  return (
    <HeaderCard
      header={COPY.FEATURES.vaultsHeader}
      body={COPY.FEATURES.vaultsBody}
      secondary
    >
<Stack sx={{ flexGrow: 1, alignItems: "center", justifyContent:"center" }}>
      <Image
                alt="vaults"
                variant="fixed-width"
                width="60%"
                mobileHeight="300px"
                query={BREAKPOINT}
                sx={{ alignSelf: "center", mb: CARD_PADDING}}
                mobileSx={{ alignSelf: "center", mb: -10 }}
                src={SCREENSHOTS.vaults}
              />
              </Stack>
    </HeaderCard>
    
  );
}


function Loops() {
  return (
    <HeaderCard
      header={COPY.FEATURES.loopsHeader}
      body={COPY.FEATURES.loopsBody}
      secondary
    >
<Stack sx={{ flexGrow: 1, alignItems: "center", justifyContent:"center" }}>

       <Image
                alt="loops"
                variant="fixed-width"
                width="60%"
                mobileHeight="300px"
                query={BREAKPOINT}
                sx={{ alignSelf: "center",mb: CARD_PADDING}}
                mobileSx={{ alignSelf: "center", mb: -10 }}
                src={SCREENSHOTS.loops}
              />
              </Stack>
    </HeaderCard>
  );
}

