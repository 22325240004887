import { APP_SIGNUP, GRAPHICS, MEDIA, COPY } from "../../constants";
import { Column, ExternalLink, Span, Image } from "../common";
import { Box, Button, Stack, styled, Typography } from "@mui/material";

const BREAKPOINT = MEDIA.XL;
export default function Hero() {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1, py: 6, alignItems: "center" }}>
        <BackgroundContent />

      <Content />
    </Stack>
  );
}

function Content() {
  return (
    <Column
      sx={{
        maxWidth: "768px",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexGrow: 1,
      }}
      spacing={4}
    >
      <Column spacing={1} sx={{
        alignItems: "center",
        textAlign: "center",
      }}>
        <Typography variant="subtitle1" sx={{ display: "inline" }}>
          {COPY.HERO.title}
        </Typography>
        <Typography sx={{ width: "80%",fontWeight: 400  }} variant="h2">
        {COPY.HERO.subtitle}
        </Typography>
      </Column>

      <ExternalLink sx={{ width: "max-content" }} href={APP_SIGNUP}>
        <Button>Join the Beta</Button>
      </ExternalLink>
    </Column>
  );
}

function BackgroundContent() {
  return (
    <Image
    sx={{ position: "absolute", top:'-40vh', zIndex: -10, left: "2vw", opacity:'0.5',
      [BREAKPOINT.below]: {
        position: "absolute",
        top: '-5vh !important'
      },
     }}
              alt="slinky"
              variant="fixed-width"
              width="160vw"
              height="auto"
              src={GRAPHICS.hero}
              
            />

  )};

const Video = styled("video")({});
function VideoContent() {
  return (
    <Stack
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        width: "400px",
        height: "400px",
        marginLeft: "-20px !important",
        marginBottom: "-170px !important",
        [BREAKPOINT.below]: {
          width: "300px",
          height: "300px",
          marginBottom: "-120px !important",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          position: "absolute",
          background: ({ palette }) =>
            `radial-gradient(transparent 40%, ${palette.background.paper} 100%)`,
        }}
      />
      <Video
        playsInline
        muted
        autoPlay
        loop
        sx={{
          mixBlendMode: "lighten",
        }}
        src={GRAPHICS.marbleVideo}
        width="100%"
        height="100%"
      />
    </Stack>
  );
}
