import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Card, Column, HeaderCard, Image, Row, Header } from "../common";
import { MEDIA, SCREENSHOTS, COPY} from "../../constants";

const CARD_PADDING = 3;
const SPACING = 3;
const BREAKPOINT = MEDIA.LG;
export default function Benefits() {
  const isMobile = useMediaQuery(BREAKPOINT.below);
  return (
    <Column spacing={6}>
      <Typography variant="h1">
        {COPY.BENEFITS.title}
      </Typography>
      {isMobile ? <Mobile /> : <Desktop />}
    </Column>
  );
}

function Desktop() {
  return (
    <Row alignItems="stretch" spacing={SPACING}>
      <Column width="65%" spacing={SPACING}>
        <Row alignItems="stretch" spacing={SPACING}>
          <Structure />
          <Collateral />
        </Row>
        <Risk />
      </Column>
      <Column width="35%">
        <Rates />
      </Column>
    </Row>
  );
}

function Mobile() {
  return (
    <Stack spacing={SPACING}>
      <Rates />
      <Structure />
      <Risk />
      <Collateral />
    </Stack>
  );
}

function Structure() {
  return (
    <HeaderCard
      header={COPY.BENEFITS.structureHeader}
      body={COPY.BENEFITS.structureBody}
    >
      <Row sx={{ justifyContent: "flex-end", overflow: "hidden" }}>
        <Image
          alt="terms"
          sx={{ mr: -2 }}
          mobileSx={{ mb: CARD_PADDING }}
          query={BREAKPOINT}
          variant="fixed-width"
          width="100%"
          height="auto"
          mobileHeight="200px"
          src={SCREENSHOTS.structure}
        />
      </Row>
    </HeaderCard>
  );
}

function Risk() {
  return (
    <Card
      sx={{
        p: 0,
        gap: 0,
        [BREAKPOINT.above]: {
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          pr: 3,
        },
      }}
    >
      <Header
        header={COPY.BENEFITS.riskHeader}
        body={COPY.BENEFITS.riskBody}
      />

      <Image
        alt="durations"
        variant="fixed-width"
        width="auto"
        src={SCREENSHOTS.risk}
      />
    </Card>
  );
}

function Collateral() {
  return (
    <HeaderCard
      header={COPY.BENEFITS.collateralHeader}
      body={COPY.BENEFITS.collateralBody}
    >
      <Image
        alt="token categories"
        sx={{ mb: CARD_PADDING }}
        variant="fixed-width"
        query={BREAKPOINT}
        width="100%"
        height="auto"
        mobileHeight="150px"
        src={SCREENSHOTS.collateral}
      />
    </HeaderCard>
  );
}

function Rates() {
  return (
    <HeaderCard
      header={COPY.BENEFITS.ratesHeader}
      body={COPY.BENEFITS.ratesBody}
    >
      <Image
        alt="rates"
        variant="fixed-width"
        width="100%"
        height="auto"
        mobileHeight="300px"
        query={BREAKPOINT}
        sx={{ alignSelf: "center" }}
        mobileSx={{ alignSelf: "center", mb: -10 }}
        src={SCREENSHOTS.rates}
      />
    </HeaderCard>
  );
}

